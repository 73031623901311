$(function () {

  // Global vars
  var $window = $(window);
  var $document = $(document);
  var $html = $('html');
  var $body = $('body');
  var $scrollDownCta = $('#heroCta');
  var isMobileDevice = $body.hasClass('mobile-device');
  var $pageBody = $('#pageBody');

  // // jump to top
  setTimeout(function () {
    $document.scrollTop(0);
  }, 2000);

  // set html overflow to visible
  disablePageScroll();

  /**
   *  INTRO ANIMATION
   */

  var $loaderCta = $('#heroCtaLoader');
  var welcome = $(".welcome").get();

  function animatePreloader() {


    // remove spinner
    TweenMax.to($("#spinner"), 0.3, {
      autoAlpha: 0, onComplete: function () {

        // show logo
        TweenMax.to(welcome, 0.6, {
          autoAlpha: 1,
          yPercent: -50,
        });

        // hide logo and start multiple layer animation
        TweenMax.to($loaderCta, 1.5, {
          y: 0, ease: Power2.EaseOuteSine, onComplete: function () {
            RevealLayerAnimation.play();
          }
        });
      }
    });
  }

  // TODO reload when not on top of page
  var heroShadow = document.getElementById('heroShadow');
  var heroImage = document.getElementById('heroImage');
  var heroHeadline = document.getElementById('heroHeadline');
  var mobileBottomExtraMargin = window.innerHeight - parseInt($('#section0').height()); // if body has class mobile-device
  var btnPosition = window.innerHeight + parseInt($('#section0').css('margin-bottom')) - mobileBottomExtraMargin;
  var $headlines = [$('.line-one-text'), $('.line-two-text'), $('.line-three-text')];


  // TweenMax.set($('#heroHeadline'), {y: -200, autoAlpha: 0})

  // TweenMax.set(heroShadow, {y: 200, autoAlpha: 0})
  // TweenMax.set(heroImage, {y: -200, autoAlpha: 0})
  TweenMax.set($headlines, {yPercent: -200, autoAlpha: 0})

  var RevealLayerAnimation = new TimelineMax(
    {
      paused: true,
      onComplete: function () {

        TweenMax.to($scrollDownCta, 0.2, {autoAlpha: 1});
        TweenMax.to($loaderCta, 0.2, {autoAlpha: 0});

        $("#loader").remove();

        enablePageScroll();
      }
    });

  RevealLayerAnimation
    .to(welcome, 0.5, {autoAlpha: 0, ease: Power2.easeIn}, 0.5)
    .to($('.loader-inner'), 0.9, {y: btnPosition, ease: Power2.easeInOut}, 0.7)
    .to($loaderCta, 0.9, {yPercent: 0, ease: Power3.easeInOut}, 0.7)
    .from(heroHeadline, 0.6, {yPercent: 4, autoAlpha: 1}, 1.1)
    .from(heroImage, 0.8, {yPercent: -10, scale: 1.2, autoAlpha: 0, ease: Power2.easeOut}, 1.1)
    .from(heroShadow, 0.8, {yPercent: 5, xPercent: 5, scale: 1.5, autoAlpha: 0, ease: Power2.easeOut}, 1.1)
    .staggerTo($headlines, 0.6, {yPercent: -20, ease: Power3.easeOut, autoAlpha: 1}, 0.2, 1)
    .to($('.loader-bg'), 0.6, {autoAlpha: 0})


  /**
   * Disable / Enable Scroll
   */

  // Disable scroll completely
  function disabledScroll(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function disablePageScroll() {
    window.addEventListener('DOMMouseScroll', disabledScroll, false);
    window.onwheel = disabledScroll; // modern standard
    window.onmousewheel = document.onmousewheel = disabledScroll; // older browsers, IE
    // use none passive event handler, otherwise preventDefault will be ignored
    // see: https://www.chromestatus.com/features/5093566007214080
    // and: https://developers.google.com/web/updates/2017/01/scrolling-intervention
    window.addEventListener('touchmove', disabledScroll, {passive: false});
    document.onkeydown = disabledScroll;
  }

  function enablePageScroll() {
    window.removeEventListener('DOMMouseScroll', disabledScroll, false);
    window.onwheel = null; // modern standard
    window.onmousewheel = document.onmousewheel = null; // older browsers, IE
    window.removeEventListener('touchmove', disabledScroll);
    document.onkeydown = null;
  }

  // fix background when overlay or intro loader is open
  function lockScroll() {

    var initWidth = $body.outerWidth();
    var initHeight = $body.outerHeight();

    // var scrollPosition = [
    //   self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
    //   self.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
    // ];

    // notice previous scroll position
    // $html.data('scroll-position', scrollPosition);
    $html.data('previous-overflow', $html.css('overflow'));

    TweenMax.set($html, {overflow: 'hidden'})

    // scroll to current position
    // window.scrollTo(scrollPosition[0], scrollPosition[1]);

    // add right margin to fix jumping window
    var marginR = $body.outerWidth() - initWidth;
    var marginB = $body.outerHeight() - initHeight;

    $body.css({'margin-right': marginR, 'margin-bottom': marginB});
    // TweenMax.set($('#navbar'), {right: marginR})
  }


  // remove fixed background when overlay or intro loader is open
  function unlockScroll() {

    // var scrollPosition = $html.data('scroll-position');

    $html.css('overflow', $html.data('previous-overflow'));

    // scroll to current position
    // window.scrollTo(scrollPosition[0], scrollPosition[1]);

    // remove right margin
    $body.css({'margin-right': 0, 'margin-bottom': 0});
    // TweenMax.set($('#navbar'), {right: 0})
  }


  /**
   * Overlay Menu
   */

  function initOverlayMenu() {

    var $menuOverlay = $('#menuOverlay');
    var $overflowWrapper = $('#overflowWrapper');
    var $menuIcon = $('#menuIcon, #menuIconDocked');
    // // only get menu items that are visible on page (i. e. no imprint)
    var $menuItems = $($menuOverlay).find('a').not(':last');
    // get sections and reverse collection, see in addActiveItemClass function below
    var menuAnimation = new TimelineMax({paused: true});

    function createTimeline() {

      // get current playhead position
      var currentPosition = menuAnimation.time();

      // clear tweens from timeline
      menuAnimation.clear();

      // create animation (use fromTo, so we can restore playhead position)
      menuAnimation
        .fromTo($menuOverlay, 0.3, {autoAlpha: 0}, {autoAlpha: 1, ease: Power0.easeInOut})
        .fromTo($menuIcon, 0.3, {autoAlpha: 1}, {autoAlpha: 0}, 0.1)
        .fromTo('#widgetIconDocked', 0.3, {autoAlpha: 1}, {autoAlpha: 0}, 0.1)
        .staggerFromTo($menuOverlay.find('a span'), 0.3, {xPercent: 120}, {xPercent: 0, ease: Sine.easeOut}, 0.1, 0.1)
        .fromTo($menuOverlay.find('.menu-close'), 0.3, {yPercent: -100}, {yPercent: 0}, 0.4)
      ;

      // restore playhead position
      menuAnimation.time(currentPosition);
    }

    function addActiveItemClass() {

      var windowScrollTop = $window.scrollTop();
      var windowHalfHeight = window.innerHeight / 2;

      // remove active class of all items
      $menuItems.removeClass('active');

      // if we scrolled not more that half the viewport height, we know we are in the topmost home section
      if (windowScrollTop < windowHalfHeight) {
        $menuItems.first().addClass('active');
      }
      else {

        var bodyHeight = $body.innerHeight();
        var bottomOffset = bodyHeight - windowScrollTop - window.innerHeight
        var bottomOffsetTreshold = window.innerHeight * 0.2;

        // if we scrolled nearby to the bottom of the page, we know we are at the last section
        if (bottomOffset <= bottomOffsetTreshold) {
          $menuItems.last().addClass('active');
        }
        else {

          // check for each element if the top of the element is in the upper half area of the screen,
          // check reversed (last section first), so we can stop if we found one that is active (performance)
          $sectionsReversed.each(function () {

            var $t = $(this);
            var elTop = $t.offset().top;
            var elTopPositionInView = elTop - windowScrollTop;

            if (elTopPositionInView < windowHalfHeight) {

              // get id of active section
              var strSectionId = $t.attr('id');
              var intSectionId = strSectionId.charAt(strSectionId.length - 1);

              // set active class
              $menuItems.eq(intSectionId).addClass('active');

              // quit foreach loop if we found one
              return false;
            }
          });
        }
      }
    }

    // Add handler to open close menu overlay
    $menuIcon.on('click', function () {
      $overflowWrapper.addClass('blur');
      $menuOverlay.addClass('open');
      menuAnimation.play();
    });

    // Add handler to close menu when not clicking a link (see stopPropagation in menu item handler below).
    // Now we don't need the close button handler anymore since we have a close handler on the whole overlay.
    $menuOverlay.on('click', function () {
      $menuOverlay.removeClass('open');
      $overflowWrapper.removeClass('blur');
      menuAnimation.reverse();
    });

    // Add handlers to scroll to a certain section
    $menuItems.on('click', function (e) {

      // prevent bubbling to not close the menu immediately
      e.stopPropagation();

      var dataSectionIndex = $(this).attr('data-section');

      // skip imprint overlay button etc.
      if (typeof dataSectionIndex !== 'undefined') {

        // scroll to section and pass callback to reverse overlay ani
        scrollToSection(dataSectionIndex, function () {
          $menuOverlay.removeClass('open');
          $overflowWrapper.removeClass('blur');
          menuAnimation.reverse();
        });

        // des hier macht anscheinend stress am mac, weil er die reverse ani während dem scroll macht..
        // TweenMax.delayedCall(0.4, function() {
        //   $menuOverlay.removeClass('open');
        //   $overflowWrapper.removeClass('blur');
        //   menuAnimation.reverse();
        // });
      }
    });


    createTimeline();

    // recreate timeline to update values
    window.addEventListener('resize', $.debounce(500, false, function () {
      createTimeline()
    }));

    window.addEventListener('scroll', $.throttle(200, false, function () {
      addActiveItemClass()
    }));
  }

  initOverlayMenu();


  /**
   * Scroll to Section and CTA
   */

  var $sectionsReversed = $($('.section').get().reverse());

  function scrollToSection(section, callback) {

    var scrollToSection;

    section = parseInt(section)
    forceShowFixedNav = true;

    // center sections but stick first section to top and last section to bottom
    if (section === 0) {
      scrollToSection = 0;
      forceShowFixedNav = false;
    }
    else if (section === $sectionsReversed.length + 1) {
      scrollToSection = $body.innerHeight() - window.innerHeight;
    }
    else {
      var $section = $('#section' + section);

      if (section === 1) {

        // always scroll to top of welcome section
        scrollToSection = $section.offset().top - (window.innerWidth < 375 ? 40 : window.innerWidth < 768 ? 30 : 0);
      }
      else if (section === 4) {

        // always scroll to top of team section
        scrollToSection = $section.offset().top - (window.innerWidth < 490 ? 40 : 0);
      }
      else {
        // get center position for other sections
        var sectionHeight = $section.height();

        // only center if window is bigger than content
        if (window.innerWidth >= 768 && sectionHeight < window.innerHeight) {

          // center about section but shift other sections a little bit up
          var delta = window.innerHeight - sectionHeight;
          var offsetAdjustment = section === 2 ? delta / 2 : delta * 0.35;

          scrollToSection = $section.offset().top - offsetAdjustment;
        }
        else {
          // otherwise scroll to top of section
          scrollToSection = $section.offset().top - (window.innerWidth < 425 ? 80 : 30);
        }
      }
    }

    TweenMax.to(window, 1, {
      scrollTo: {
        y: scrollToSection, // scroll to section by item or by offset value
        autoKill: false
      },
      ease: Power3.easeOut,
      onStart: disablePageScroll,
      onComplete: function () {
        enablePageScroll();
        forceShowFixedNav = false;

        // run callback
        if (typeof callback === 'function') {
          callback();
        }
      }
    });
  }

  $scrollDownCta.on('click', function () {
    scrollToSection(1);
  });

  $('#btnMore').on('click', function () {
    scrollToSection(2)
  });


  /**
   * Docked Navigation
   */

  var navbarIsDocked = true; // set to true so it will be hidden on load
  var $dockedItems = $('#dockedItems');
  var forceShowFixedNav = false;
  var forceHideFixedNav = false;

  function showDockedNav() {
    TweenMax.to($dockedItems, 0.3, {y: 0, ease: Power2.easeOut});
    navbarIsDocked = true;
  }

  function hideDockedNav() {
    TweenMax.to($dockedItems, 0.3, {y: -200, ease: Power2.easeIn});
    navbarIsDocked = false;
  }

  function dockedNavigationListener() {

    var docViewTop = $window.scrollTop();
    var heroHeight = $pageBody.offset().top - 5; // add some treshold for safety

    // check if scroll top is bigger than hero height (or forced)
    if (!forceHideFixedNav && !navbarIsDocked && (forceShowFixedNav || docViewTop >= heroHeight)) {
      showDockedNav()
    }
    else if (navbarIsDocked && !forceShowFixedNav && docViewTop <= heroHeight) {
      hideDockedNav()
    }
  }

  window.addEventListener('scroll', $.throttle(250, false, dockedNavigationListener));

  dockedNavigationListener();


  /**
   * Open / Close Widget
   */

  var $widgetSidebar = $('#widgetSidebar');
  var $widgetBtn = $('#widgetBtn, #widgetIconDocked');
  var $widgetClose = $('#widgetClose');
  var $widgetTransparent = $('#widgetTransparent');
  var widgetAnimation = new TimelineMax({paused: true});

  // hide widget by default
  TweenMax.set($widgetSidebar, {xPercent: 100});
  TweenMax.set($widgetClose, {rotation: 360, xPercent: 100, autoAlpha: 0});
  TweenMax.set($widgetTransparent, {autoAlpha: 0});

  widgetAnimation
    .to($widgetTransparent, 0.1, {autoAlpha: 1}, 0)
    .to($widgetSidebar, 0.5, {xPercent: 0, ease: Sine.easeOut}, 0)
    .to($widgetClose, 0.3, {rotation: 0, xPercent: 0, autoAlpha: 1}, 0.2)

  $widgetTransparent.on('click', function () {
    widgetAnimation.reverse();
    TweenMax.set($widgetTransparent, {clearProps: 'all'})
  });

  $widgetBtn.on('click', function () {
    widgetAnimation.play();
    TweenMax.set($widgetTransparent, {display: 'block'})
  });

  $widgetClose.on('click', function () {
    widgetAnimation.reverse();
    TweenMax.set($widgetTransparent, {clearProps: 'all'})
  });

  /**
   * Calculate and set rounded height of page body sections
   */

  var $pageBodySections = $pageBody.find('.section');
  var $pageBodyTextBoxeas = $pageBody.find('.textbox:not(.autoheight)');
  var mqAutoCalculation = window.matchMedia('(max-width: 2000px)');
  // var mqAutoCalculation = window.matchMedia('(min-width: 992px) and (max-width: 2000px)');
  var sectionsRatios = [0.625, 0.48, 0.55];

  //var sectionsRatiosSm = [0.625, 0.48, 0.55];

  function setPageBodySectionHeights() {

    $pageBodySections.each(function (i, section) {

      TweenLite.set(section, {clearProps: 'height'});

      var contentWidth = !mqAutoCalculation.matches ? 2000 : window.innerWidth;
      var ratio = sectionsRatios[i]; //window.innerWidth < 992 ? sectionsRatiosSm[i] : sectionsRatios[i];

      if (typeof ratio === 'undefined') {
        return;
      }
      var newHeight = Math.round(contentWidth * ratio);

      // set rounded height, to fix flexbox buggy alignment with float values
      //TweenLite.set(section, {height: newHeight % 2 === 0 ? newHeight : newHeight++});
    });
  }

  function setPageBodyTextBlockHeights() {

    $pageBodyTextBoxeas.each(function (i, section) {

      TweenLite.set(section, {clearProps: 'height'});

      var newHeight = Math.round($(section).height());

      // set rounded height, to fix flexbox buggy alignment with float values
      TweenLite.set(section, {height: newHeight % 2 === 0 ? newHeight : newHeight++});
    });
  }

  function checkAutoCalculationMq(mq) {

    if (mq.matches) {
      window.addEventListener('resize', setPageBodySectionHeights);
      window.addEventListener('resize', setPageBodyTextBlockHeights);
    }
    else {
      window.removeEventListener('resize', setPageBodySectionHeights);
      window.removeEventListener('resize', setPageBodyTextBlockHeights);
    }
  }

  setPageBodySectionHeights();
  setPageBodyTextBlockHeights();
  checkAutoCalculationMq(mqAutoCalculation);

  mqAutoCalculation.addListener(checkAutoCalculationMq);


  /**
   * Google Maps (widget and footer)
   */

  function initGmaps() {

    // var address = "Krammel & Haase Vimystraße 6, Freising";
    var placeId = 'ChIJz3Z7_d1qnkcRbKvXsZH2woQ';
    var latlngCenter = new google.maps.LatLng(48.406142, 11.740430);
    // var geocoder = new google.maps.Geocoder();
    var closeTimeout;
    var myOptions = {
      scrollwheel: false,
      draggable: true,
      zoom: 16,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      center: latlngCenter,
      //disableDefaultUI : true, // disableDefaultUI: true can replace navigationControl: false, mapTypeControl: false, scaleControl: false
      navigationControl: false,
      mapTypeControl: true,
      scaleControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false,
      panControl: true,
      // disable pan with two finger and tending the map with one finger on mobile devices
      // see: https://developers.google.com/maps/documentation/javascript/interaction
      // (not working with disabled page scroll and without disabled page scroll you can hide
      // the close map button by scrolling the map
      //gestureHandling: 'none',
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {"color": "#60728c"}
          ]
        }, {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {"color": "#60728c"},
            {"lightness": 40}
          ]
        }, {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {"color": "#2dcad4"}
          ]
        }, {
          "elementType": "geometry.stroke"
        }, {
          "featureType": "transit.line",
          "stylers": [
            {"color": "#60728c"},
            {"lightness": 40}
          ]
        }, {
          "elementType": "labels.text.fill",
          "stylers": [
            {"color": "#60728c"},
            {"lightness": -100}
          ]
        }, {
          "elementType": "labels.text.stroke",
          "stylers": [
            {"color": "#60728c"},
            {"lightness": 40}
          ]
        }, {
          "featureType": "water",
          "elementType": "labels.text.stroke",
          "stylers": [
            {"color": "#2dcad4"}
          ]
        }]
    };

    // var overlay = gmapOpenBtn.parents('.overlay');

    /**
     * load a gmap intro a given element
     *
     * @param targetElement
     * @param map
     * @param _delay
     * @param _callback
     */
    function loadGmap($targetElement, map, _delay, _callback) {

      if (!$targetElement.length) {
        return;
      }

      var targetElement = $targetElement.get(0);

      map = new google.maps.Map(targetElement, myOptions);

      // hide container
      TweenMax.set($targetElement, {autoAlpha: 0});

      // // find address by string to set center and add the marker
      // geocoder.geocode({'address': address}, function (results, status) {
      //
      //   if (status == google.maps.GeocoderStatus.OK) {
      //
      //     // center maps on marker
      //     map.setCenter(results[0].geometry.location['lng']);

      // add place
      var placeService = new google.maps.places.PlacesService(map);

      function createMarker(place) {
        // setup array for dims to better calculate the retina versions
        // [width, height, anchor top, anchor left]
        // var iconDimsArray = [180, 198, 62, 186];
        var iconDimsArray = [90, 99, 31, 93];
        // if (window.devicePixelRatio >= 1.5) {
        //   for (var i = 0; i < iconDimsArray.length; i++) {
        //     iconDimsArray[i] = Math.floor(iconDimsArray[i] / 2);
        //   }
        // }

        // build icon
        var iconData = {
          url: window.devicePixelRatio >= 1.5 ? '/img/gmap-marker@x2.13092024.png' : '/img/gmap-marker.13092024.png',
          size: new google.maps.Size(iconDimsArray[0], iconDimsArray[1]),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(iconDimsArray[2], iconDimsArray[3])
        };

        // build icon hover area
        var icon = new google.maps.MarkerImage(
          iconData.url,
          iconData.size,
          iconData.origin,
          iconData.anchor,
          iconData.size
        );
        // var hoverAreaShape = {
        //   coord: [16, 76, 34, 24, 98, 0, 156, 24, 174, 76, 152, 130, 98, 152, 38, 130],
        //   type: 'poly'
        // };

        // build the marker and add it to map
        var marker = new google.maps.Marker({
          map: map,
          icon: icon,
          //shape: hoverAreaShape,
          title: place.name,
          position: place.geometry.location,
        });

        // click handler for marker
        var infowindow = new google.maps.InfoWindow({
          content: '<div class="poi-info-window gm-style">' +
          '<div class="title full-width">Zahnarztpraxis <br>Krammel & Haase</div>' +
          '<div class="address"><div class="address-line full-width">Vimystraße 6</div><div class="address-line full-width">85354 Freising</div></div>' +
          '<div class="view-link"><a target="_blank" href="https://www.google.de/maps/place/Zahnarztpraxis+Krammel+%26+Haase/@48.4054836,11.7382412,17z/data=!3m1!4b1!4m5!3m4!1s0x479e6addfd7b76cf:0x84c2f691b1d7ab6c!8m2!3d48.4054801!4d11.7404299"><span>In Google Maps ansehen</span></a></div>' +
          '</div>' +
          '</div>'
        });

        // Open on marker click
        google.maps.event.addListener(marker, 'click', function () {
          infowindow.open(map, this);
        });

        // load info window styles (because they only get loaded by google when clicking on a default marker from the map)
        $('<style type="text/css"></style>').html('.poi-info-window div,.poi-info-window a{color:#333;font-family:Roboto,Arial;font-size:13px;background-color:white;-moz-user-select:text;-webkit-user-select:text;-ms-user-select:text;user-select:text}.poi-info-window{cursor:default;margin-top:3px}.poi-info-window a:link{text-decoration:none;color:#427fed}.poi-info-window .view-link,.poi-info-window a:visited{color:#427fed}.poi-info-window .view-link:hover,.poi-info-window a:hover{cursor:pointer;text-decoration:underline}.poi-info-window .full-width{width:180px}.poi-info-window .title{overflow:hidden;font-weight:500;font-size:14px}.poi-info-window .address{margin-top:2px;color:#555}.poi-info-window .view-link{margin-top: 6px;}').appendTo("head");
      }

      function placeCallback(place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          createMarker(place);
        }
      }

      placeService.getDetails({
        placeId: placeId
      }, placeCallback);

      //   }
      //   else {
      //     alert('Geocode was not successful for the following reason: ' + status);
      //   }
      // });

      // give the map some time to load
      setTimeout(function () {

        // fade in container again and fire callback
        TweenMax.to($targetElement, 0.2, {autoAlpha: 1/*, function: _callback*/});

        if (typeof _callback === 'function') {
          _callback();
        }

      }, _delay);
    }

    function unloadGmap($targetElement, map) {

      closeTimeout && clearTimeout(closeTimeout);

      // overlay.removeClass('open');

      closeTimeout = setTimeout(function () {
        // unload gmap totally in IOS for performance
        $targetElement.removeClass('cached');
        $targetElement.empty();
        map = null;
      }, 1);
    }


    /**
     * Footer Map
     */
    var gmap;
    var gmapCached = false;
    var $footerMap = $("#footerMap");
    var $btnShowMap = $('#btnShowMap');
    var $btnHideMap = $('#btnHideMap');
    var $cardLeft = $('.card.left');
    var $cardRight = $('.card.right');
    var $footerContent = $('#footerContent');
    var $footerInner = $footerContent.find('.footer-inner');
    var $imprintBar = $footerContent.find('#imprintBar');

    // button ani
    var footerMapButtonAni = new TimelineMax({paused: true});
    footerMapButtonAni.to($btnShowMap.find('span'), 0.15, {autoAlpha: 0, ease: Power1.EaseInOut}, 0);
    footerMapButtonAni.to($btnShowMap, 0.4, {scaleX: 0, ease: Power2.EaseInExpo});

    // map reveal ani
    var footerMapAnimation = new TimelineMax({
      paused: true,
      onStart: function () {
        if (isMobileDevice) {
          forceHideFixedNav = true;
          disablePageScroll()
          hideDockedNav()
        }
      },
      onReverseComplete: function () {
        if (isMobileDevice) {
          forceHideFixedNav = false;
          enablePageScroll()
          showDockedNav()
        }
      }
    });
    footerMapAnimation.to($cardLeft, 0.3, {x: "-100%", autoAlpha: 0});
    footerMapAnimation.to($cardRight, 0.3, {x: "100%", autoAlpha: 0}, 0);
    footerMapAnimation.to($btnShowMap, 0.3, {y: "100%", autoAlpha: 0}, 0);
    footerMapAnimation.to($imprintBar, 0.3, {y: "100%"}, 0);
    footerMapAnimation.to($btnHideMap, 0.3, {y: "0"}, 0.2);
    footerMapAnimation.to($footerContent, 0, {className: "+=map-visible"});
    footerMapAnimation.set($footerInner, {display: 'none'});

    // open handler
    $btnShowMap.on('click', function () {

      footerMapButtonAni.play();

      if (gmapCached) {
        footerMapAnimation.play();
      }
      else {
        // add class to instantly show the map container, because the map will not
        // render correctly, if the container is hidden when the map gets loaded
        $footerMap.addClass('visible-for-load');

        loadGmap($footerMap, gmap, 600, function () {
          footerMapAnimation.play();
          gmapCached = true;
        })
      }
    });

    // close handler
    $btnHideMap.on('click', function () {

      $footerMap.removeClass('visible-for-load');

      // unload if not mobile device
      if (isMobileDevice) {
        unloadGmap($footerMap, gmap);
        gmapCached = false;
      }

      // $footerContent.removeClass('map-visible');
      // TweenMax.set($footerInner, {clearProps: 'all'});
      footerMapButtonAni.reverse().seek(0);
      footerMapAnimation.reverse();
    });

    // init map initially on non mobile devices
    // if (!isMobileDevice) {
    //   loadGmap($footerMap, gmap)
    //   gmapCached = true;
    // }


    /**
     * Widget Map
     */
    var gmapWidget;
    var gmapWidgetCached = false;
    var $widgetMap = $("#widgetMap");
    var $showWidgetMap = $('#showMapWidget');
    var $hideWidgetMap = $('#hideWidgetMap');
    var $widgetBottom = $('#widgetBottom');

    // // button ani
    // var widgetMapButtonAni = new TimelineMax({paused: true});
    // widgetMapButtonAni.to($showWidgetMap.find('span'), 0.15, {autoAlpha: 0, ease: Power1.EaseInOut}, 0);
    // widgetMapButtonAni.to($showWidgetMap, 0.4, {scaleX: 0, ease: Power2.EaseInExpo});

    // map reveal ani
    var toggleWidgetMapAni = new TimelineMax({paused: true});
    toggleWidgetMapAni.to([$widgetBottom, $showWidgetMap], 0.3, {autoAlpha: 0}, 0);
    toggleWidgetMapAni.to($hideWidgetMap, 0.3, {y: 0}, 0);
    toggleWidgetMapAni.set($widgetMap, {className: "+=map-open"});
    toggleWidgetMapAni.set($widgetBottom, {display: 'none'});

    $showWidgetMap.on('click', function () {

      // widgetMapButtonAni.play();

      if (gmapWidgetCached) {
        toggleWidgetMapAni.play();
      }
      else {

        loadGmap($widgetMap, gmapWidget, 600, function () {
          toggleWidgetMapAni.play();
          gmapWidgetCached = true;
        })
      }
    });

    $hideWidgetMap.on('click', function () {

      unloadGmap($widgetMap, gmapWidget);
      gmapWidgetCached = false;

      // widgetMapButtonAni.reverse().seek(0);
      toggleWidgetMapAni.reverse();
    });

  }

  initGmaps();


  var scene = document.getElementById('heroScene');
  var heroParallax = new Parallax(scene, {
    relativeInput: true,
    limitX: 100,
    limitY: 100
  });


  /**
   * About section w/ gallery
   */

  function initGallery() {

    // Background Image Fader
    var $gallery = $('#gallery');
    var $galleryTiles = $gallery.find('.mask');
    var $galleryImageContainers = $galleryTiles.find('.img-container');
    var $aboutMenuItems = $('#aboutMenu').find('button');
    var $aboutHeadline = $('#aboutHeadline');
    var $aboutText = $('#aboutText');
    var $aboutButton = $('#aboutButton');
    var currentGallery;
    var currentGalleryImage;
    var isAnimating = false;
    var galleries = [{
      name: 'praxis',
      headline: "<span class=\"color\">Hoher Anspruch &</span><br/>moderne Technik",
      text: 'Unsere Praxis befindet sich 5 Gehminuten von der Frei&shy;singer Innen&shy;stadt entfernt und liegt am ' +
      'Prinzen&shy;park in Freising (ehemals Vimy&shy;kaserne). Mit großer Begeis&shy;terung für unsere tägliche Arbeit ' +
      'bieten wir Ihnen eine zahn&shy;ärztliche Betreu&shy;ung auf hohem Niveau. Moderne Techno&shy;logie unter&shy;stützt ' +
      'uns bei allen Arbeits&shy;schritten.',
      buttonText: 'Labor entdecken',
      images: ['IMG_9279.13092024.jpg', 'IMG_9295.13092024.jpg', 'IMG_9254.13092024.jpg']
    }, {
      name: 'labor',
      headline: "Ihr <span class=\"color\">Einblick</span> in<br/>unser Labor",
      text: 'Um qualitativ hochwertig arbeiten zu können, befindet sich ein eigenes Labor in unserem Haus. ' +
      'Die enge Zusammen&shy;arbeit und die Absprache zwischen Zahnarzt und Zahn&shy;techniker ermöglichen schnellere und ' +
      'hoch&shy;quali&shy;tative Ergebnisse. Reparaturen an Ihrem Zahnersatz können meist innerhalb weniger Stunden vor Ort ' +
      'erledigt werden.',
      buttonText: 'Zurück zur Praxis',
      images: ['labor_01.13092024.jpg']
    }];

    function setGallery(index) {

      // set active menu item
      $aboutMenuItems.removeClass('active').filter(':eq(' + index + ')').addClass('active');

      if (index === currentGallery) {
        return;
      }

      var gallery = galleries[index];
      var headline = gallery.headline;
      var text = gallery.text;
      var buttonText = gallery.buttonText;

      var multiplier = index < currentGallery ? -1 : 1

      // toggle headline
      // TweenMax.to($aboutHeadline, 0.5, {scrambleText: {text: headline, chars:"A B C D E F G H I J K L M N O P Q R S T U V W X Y Z ", revealDelay: 0.5, speed: 0.3, tweenLength: true}});
      $aboutHeadline.addClass('out');


      TweenMax.to($aboutHeadline, 0.5, {
        yPercent: -10 * multiplier, opacity: 0, ease: Power3.easeIn, onComplete: function () {

          $aboutHeadline.html(headline);

          TweenMax.set($aboutHeadline, {yPercent: 10 * multiplier});
          TweenMax.to($aboutHeadline, 0.7, {yPercent: 0, opacity: 1, ease: Power3.easeOut});
        }
      });

      TweenMax.to($aboutButton, 0.5, {
        yPercent: -30 * multiplier, opacity: 0, ease: Power3.easeIn, onComplete: function () {

          $aboutButton.find('span:first').html(buttonText);

          TweenMax.set($aboutButton, {yPercent: 30 * multiplier});
          TweenMax.to($aboutButton, 0.7, {yPercent: 0, opacity: 1, ease: Power3.easeOut});
        }
      });

      TweenMax.delayedCall(0.5, function () {
        if ($aboutHeadline.parents('.animation-wrapper').hasClass('in-view')) {
          $aboutHeadline.removeClass('out');
        }
      });

      // toggle text
      // TweenMax.to($aboutText, 0.5, {scrambleText: {text: headline, chars:"A B C D E F G H I J K L M N O P Q R S T U V W X Y Z ", revealDelay: 0.5, speed: 0.3, tweenLength: true}});
      TweenMax.to($aboutText, 0.5, {
        yPercent: -10 * multiplier, opacity: 0, ease: Power3.easeIn, onComplete: function () {

          $aboutText.html(text);

          TweenMax.set($aboutText, {yPercent: 10 * multiplier, ease: Power3.easeOut});
          TweenMax.to($aboutText, 0.7, {yPercent: 0, opacity: 1, ease: Power3.easeOut});
        }
      });

      // init gallery if not set
      if (currentGallery == null) {
        var image = '<img src="img/gallery-' + gallery['name'] + '/' + gallery['images'][0] + '" style="opacity:0"/>';
        $gallery.find('.active-image').html(image);
      }

      // show first image of gallery
      showImage(index, 0);

      // build navigation
      buildGalleryNav()
    }

    function buildGalleryNav() {

      // remove old nav
      $gallery.find('.nav').fadeOut(function () {
        $(this).remove();
      });

      var items = galleries[currentGallery]['images'];

      // don't create nav if only one image
      if (items.length === 1) {
        return;
      }

      var $nav = $('<ul class="nav"/>').hide();

      for (var i = 0; i < items.length; i++) {

        var $li = $('<li><span></span></li>');

        if (i === 0) {
          $li.addClass('active');
        }

        $li.on('click', (function (idx, $nav) {

          // use closure (like arrow function in es6)
          return function () {

            // quit if already active
            if (currentGalleryImage === idx) {
              return;
            }

            showImage(currentGallery, idx);
          }
        })(i, $nav));

        $nav.append($li);
      }

      $gallery.append($nav);
      $nav.fadeIn();
    }

    function showImage(galleryIdx, idx) {

      var gallery = galleries[galleryIdx];
      var switchGallery = galleryIdx !== currentGallery;
      var inverseAnimation = switchGallery
        ? galleryIdx < currentGallery
        : currentGalleryImage < idx;

      // jump to first if reached end
      if (idx >= gallery['images'].length) {
        idx = 0;
      }
      else if (idx < 0) {
        idx = gallery['images'].length - 1;
      }

      // quit if same image
      if (currentGallery === galleryIdx && (isAnimating || idx === currentGalleryImage)) {
        return;
      }

      isAnimating = true;

      // swap active nav item
      $gallery.find('.nav li').removeClass('active').filter(':eq(' + idx + ')').addClass('active');

      // swap image
      currentGallery = galleryIdx;
      currentGalleryImage = idx;

      var $active = $gallery.find('.active-image');

      // add new image to DOM and count down timeToWait
      $active.each(function (i, e) {

        var $next = $('<div class="image"><img src="img/gallery-' + gallery['name'] + '/' + gallery['images'][idx] + '"/></div>');

        // wait for image to be loaded, then fade in. (only on
        // first item, since its the same image for all items)
        if (i === 0) $next.imagesLoaded(function () {

          var multiplier = inverseAnimation ? -1 : 1;
          var $oldImages = $gallery.find('.active-image');
          var $newImages = $gallery.find('.image:not(.active-image)').addClass('active-image');

          if (inverseAnimation) {
            $oldImages = $($oldImages.get().reverse());
            $newImages = $($newImages.get().reverse());
          }

          $oldImages.removeClass('active-image');

          var tl = new TimelineMax({
            onComplete: function () {
              $oldImages.remove();
            }
          });

          if (!switchGallery) {
            TweenMax.set($newImages, {x: 40 * multiplier, opacity: 0});
            tl.staggerTo($newImages, 0.5, {x: 0, opacity: 1}, 0.1);
          }
          else {
            TweenMax.set($newImages, {yPercent: 100 * multiplier, opacity: 1});
            tl.staggerTo($oldImages, 1, {yPercent: -100 * multiplier, ease: Power4.easeInOut}, 0.1, 0);
            tl.staggerTo($newImages, 1, {yPercent: 0, ease: Power4.easeInOut}, 0.1, 0);
          }

          TweenMax.delayedCall(1, function () {
            isAnimating = false;
          })

        });

        $(e).after($next);
      });
    }

    var currentWidth = 0;

    function updateGalleryDimensions() {

      // only resize if currentWidth not set or currentWidth !== window.innerWidth
      if (currentWidth > 0 && currentWidth === window.innerWidth) {
        return;
      }

      currentWidth = window.innerWidth;

      var widthOfFirstImage = parseInt($galleryTiles.first().width());
      var widthOfTiles = parseInt($galleryTiles.filter(':eq(1)').width());
      var marginBetweenImages = parseInt($galleryTiles.filter(':eq(1)').css('margin-left'));

      // set width of all tiles to match the first one
      //$galleryImageContainers.width(widthOfFirstImage);

      // get and set offset of 2nd image
      var offset = widthOfFirstImage + marginBetweenImages;
      $galleryImageContainers.first().css('left', -offset);

      // get and set offset of 3rd image
      offset += widthOfTiles + marginBetweenImages;
      $galleryImageContainers.filter(':eq(1)').css('left', -offset);
    }

    // add handler for about menu
    $aboutMenuItems.on('click', function (e) {

      // var $item = $(this);
      var galleryIndex = $aboutMenuItems.index(this);

      // toggle gallery
      setGallery(galleryIndex);
    });

    // add handler for CTA buttons
    $aboutButton.on('click', function () {
      setGallery(currentGallery === 0 ? 1 : 0);
    });

    // swipe support
    $gallery.swipe({
      preventDefaultEvents: false,
      swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

        if (fingerCount <= 2) {

          if (direction === 'left') {
            showImage(currentGallery, currentGalleryImage + 1)
          }
          else if (direction === 'right') {
            showImage(currentGallery, currentGalleryImage - 1)
          }
        }
      }
    });

    // add resize listener and fire once
    window.addEventListener('resize', updateGalleryDimensions);
    updateGalleryDimensions();

    // set initial gallery
    setGallery(0);
  }

  initGallery();


  /**
   * Services Animation
   */

  function initServices() {

    var $btnServices = $('#btnServices');
    var $serviceBack = $('#serviceBack');
    var $textServices = $('#textServices');
    var $serviceDetailBox = $('#serviceDetailBox');
    var $serviceImg = $('#serviceImg');
    var $serviceNav = $('#serviceNav');
    var $serviceTexts = $('.service-text');

    var $serviceNavItems = $serviceNav.find('li a');

    $serviceNavItems.on('click', function () {
      var $this = $(this);
      var activeNavIndex = $serviceNavItems.index($this);
      var $activeTextEl = $serviceTexts.filter(':eq(' + activeNavIndex + ')');

      // nav active
      $serviceNavItems.removeClass('active')
      $this.addClass('active')

      // text active
      // $serviceTexts.removeClass('active')
      TweenMax.set($serviceTexts, {clearProps: 'all'});

      // $activeTextEl.addClass('active');

      TweenMax.set($activeTextEl, {
        display: 'block', onComplete: function () {
          TweenMax.to($activeTextEl, 0.4, {y: 0, autoAlpha: 1})
        }
      })

    });

    // TODO reset nav links after close services

    function switchText(fadeOutEl, fadeInEl) {

      // add helper class to make headline animation work. the before element is completely gone (see inspector) with
      // display none and won't animate in because of the transition delay of the headlines :after element so we need
      // to temporarily remove the animation delay via this helper class (see globals.scss)
      if ($textServices === fadeInEl) {
        fadeInEl.addClass('gsap-animation-override-fix');
      }

      // Animate text out and new in
      TweenMax.to(fadeOutEl, 0.3, {
        xPercent: -100, autoAlpha: 0, ease: Power1.easeInOut, onComplete: function () {
          TweenMax.set(fadeOutEl, {display: 'none'})

          TweenMax.set(fadeInEl, {
            display: 'block', onComplete: function () {
              TweenMax.to(fadeInEl, 0.3, {xPercent: 0, ease: Power1.easeInOut, autoAlpha: 1})

              // remove helper class (delayed, otherwise not working)
              if ($textServices === fadeInEl) {
                TweenMax.delayedCall(0.2, function () {
                  fadeInEl.removeClass('gsap-animation-override-fix');
                })
              }
            }
          });
        }
      });
    }

    function switchLeftContent(fadeOutEl, fadeInEl) {

      // Animate img out and nav in
      TweenMax.to(fadeOutEl, 0.3, {
        xPercent: 100, autoAlpha: 0, ease: Power1.easeInOut, onComplete: function () {
          TweenMax.set(fadeOutEl, {display: 'none'})

          TweenMax.set(fadeInEl, {
            display: 'block', ease: Power1.easeInOut, onComplete: function () {
              TweenMax.to(fadeInEl, 0.3, {xPercent: 0, autoAlpha: 1})
            }
          });
        }
      });
    }

    function getBodyScrollTop() {
      return (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    }

    function setBodyScrollTop(value) {
      TweenMax.set(window, {scrollTo: value});
    }

    function toggleMobileAccordionItem(index) {

      var $item = $serviceTexts.eq(index);
      var isOpen = $item.hasClass('mobile-active');
      // var hasOpenBeforeElement = $serviceTexts.filter(':lt(' + index + ')').filter('.mobile-active').length;
      // var originalOffsetOnToggleStart = getBodyScrollTop();
      //
      // // get original height of the box
      // var diffHeight = $serviceDetailBox.outerHeight();

      // console.log(originalOffsetOnToggleStart, diffHeight)

      // close all items
      $serviceTexts.removeClass('mobile-active');

      // toggle requested item
      if (isOpen) {
        $item.removeClass('mobile-active');
      }
      else {
        $item.addClass('mobile-active');
      }

      // if (hasOpenBeforeElement) {
      //   // get new height of the box
      //   diffHeight -= $serviceDetailBox.outerHeight();
      //   // console.log(diffHeight)
      //
      //   // Set scrollTop to same position as before when the career has been opened. This works out
      //   // of the box in mobile chrome but safari is stupid so we have to do this via javascript
      //   setBodyScrollTop(originalOffsetOnToggleStart - diffHeight);
      // }

      // scroll to item if not in view
      //if ($item.offset().top < getBodyScrollTop() + 30) {


      // TweenMax.set(window, {scrollTo: $item.offset().top - 80});


      // setTimeout(function() {
      //   TweenMax.to(window, 0.5, {scrollTo: $item.offset().top - 80});
      // }, 5);
      //}
    }

    $serviceDetailBox.find('h3').on('click', function () {

      var index = $serviceTexts.index($(this).parent());

      toggleMobileAccordionItem(index);
    });


    $btnServices.on('click', function () {

      // fade in back btn
      TweenMax.to($serviceBack, 0.3, {x: 0, delay: 0.5, autoAlpha: 1})

      switchText($textServices, $serviceDetailBox);
      switchLeftContent($serviceImg, $serviceNav)
    });

    $serviceBack.on('click', function () {
      // fade out back btn
      TweenMax.to($serviceBack, 0.3, {x: -100, autoAlpha: 0})

      switchText($serviceDetailBox, $textServices);
      switchLeftContent($serviceNav, $serviceImg)
    });

    $serviceNavItems.first().click();
  }

  initServices();


  /**
   * Team section
   */

    // we need these variables outside
  var teamParallax;
  var teamParallaxInitialized = false;

  function initTeam() {

    // Parallax for group image
    var isSliderOpen = false;
    var isSliding = false;
    var $teamScene = document.getElementById('teamScene');

    function initParallax() {

      if (!teamParallaxInitialized) {
        teamParallaxInitialized = true;
        teamParallax = new Parallax($teamScene, {
          relativeInput: true,
          limitX: 50,
          limitY: 10
        });

      }
    }

    function destroyParallax() {

      if (teamParallaxInitialized) {
        teamParallax.destroy();
        teamParallaxInitialized = false;
      }
    }

    // Team slider
    var $teamSliderWrapper = $('#teamSliderWrapper');
    var $slider = $('<div class="team-slider"></div>');
    var $buttonRight, $buttonLeft;
    var $switchDot = $('#switchDot');
    var $switchDotOriginalParent = $switchDot.parent();
    var mqSliderOnly = matchMedia("(max-width: 767px)");
    var isFirstButtonVisible = false;

    var arrTeam = [{
      title: "Zahnärzte",
      member: [{
        name: "Stephanie Krammel",
        job: "Zahnärztin",
        image: "01.webp",
        vita: "<ul>" +
        "<li>Implantologie durch Curriculum</li>" +
        "<li>Ästhetische sowie digitale Zahnheilkunde</li>" +
        "<li>Prothetik</li>" +
        "<li>Kinderzahnheilkunde</li>" +
        "<li>CAD/CAM</li>" +
        "</ul>"
      }, {
        name: "Julia Haase",
        job: "Zahnärztin",
        image: "02.webp",
        vita: "<ul>" +
        "<li>Endodontie durch Curriculum</li>" +
        "<li>Parodontologie durch Curriculum</li>" +
        "<li>Prothetik</li>" +
        "<li>CAD/CAM</li>" +
        "<li>Kinderzahnheilkunde</li>" +
        "</ul>"
      }, {
        name: "Dr. Lucia Holzner",
        job: "Zahnärztin",
        image: "03.webp",
        vita: "<ul>" +
            "<li>Endodontologie</li>" +
            "<li>Implantologie durch Curriculum</li>" +
            "<li>Ästhetische sowie digitale Zahnheilkunde</li>" +
            "<li>Prothetik</li>" +
            "<li>Kinderzahnheilkunde</li>" +
            "<li>CAD/CAM</li>" +
            "</ul>"
      }]
    }, {
      title: "Zahntechniker",
      member: [{
        name: "Lena Scherrer",
        job: "Zahntechnikerin",
        image: "04.webp"
      }, {
        name: "Ruth Pollinger",
        job: "Zahntechnikerin",
        image: "05.webp"
      }]
    },
      {
        title: "Assistenten",
        member: [{
          name: "Sabine Huber",
          job: "ZMV und Praxismanagerin",
          image: "06.webp",
        }, {
          name: "Kristina Hobmeier",
          job: "Zahnmedizinische Fachangestellte (ZFA)",
          image: "08.webp",
        }, {
          name: "Iris Bruckmaier",
          job: "Zahnmedizinische Fachangestellte (ZFA)",
          image: "09.webp",
        }, {
          name: "Brigitte Kerscher",
          job: "Zahnmedizinische Prophylaxeassistentin (ZMP)",
          image: "10.webp",
        }, {
          name: "Angela Riedl",
          job: "Zahnmedizinische Fachangestellte (ZFA)",
          image: "11.webp",
        }, {
          name: "Dilan Türksoy",
          job: "Zahnmedizinische Fachangestellte (ZFA), Verwaltung",
          image: "12.webp",
        }, {
          name: "Sonja Schmied",
          job: "Zahnmedizinische Prophylaxeassistentin (ZMP)",
          image: "13.10102024.webp",
        }, {
          name: "Melanie Voss",
          job: "DH-Dentalhygienikerin",
          image: "14.webp",
        }, {
          name: "Diana Shygaieva",
          job: "Auszubildende",
          image: "07.webp",
        }]
      }];

    function slide(itemsCount) {

      if (isSliding) {
        return;
      }

      isSliding = true;

      // TODO: always center next item in mobile view
      var distance = mqSliderOnly.matches ? 167 / 2 * itemsCount : 0.162 * window.innerWidth * itemsCount;
      var currentX = $slider.get(0)._gsTransform ? $slider.get(0)._gsTransform.x : 0;
      var minX = 0;
      var maxX = -$slider.outerWidth() + window.innerWidth;
      var newX = Math.max(Math.min(currentX + distance, minX), maxX)
      // console.log("currentX: " + currentX, "distance: " + distance, "maxX: " + maxX, "newX: " + newX);

      TweenMax.to($slider, 0.3, {
        x: newX, onComplete: function () {
          isSliding = false;
        }
      });

      // show hide left button
      toggleLeftButton(newX)
    }

    function toggleLeftButton(newX) {

      if (newX < -10 && !isFirstButtonVisible) {
        TweenMax.to($buttonLeft, 0.5, {xPercent: 0, autoAlpha: 1})
        isFirstButtonVisible = true;
      }
      else if (isFirstButtonVisible) {
        TweenMax.to($buttonLeft, 0.5, {xPercent: -100, autoAlpha: 0})
        isFirstButtonVisible = false;
      }
    }

    function appendItem(obj, groupIdx) {

      // var hoverClass = obj.vita ? 'hover-vita' : 'no-hover';

      var $item = $(
        '<div class="card">' +
        '<div class="bg" style="background-image:url(\'img/team/' + obj.image + '\')"></div>' +
        '<div class="overlay">' +
        '<div class="name">' + obj.name + '</div>' +
        '<div class="job">' + obj.job + '</div>' +
        '<div class="vita">' + String(obj.vita) + '</div>' +
        '</div>' +
        '</div>'
      );

      if (obj.vita) {
        $item.addClass('hoverable');
      }

      // TODO: add listener and stuff

      $slider.append($item);
    }

    function buildSlider() {

      arrTeam.forEach(function (objGroup, groupIdx) {

        // add group title
        $slider.append('<div class="group-title"><h3 class="h">' + objGroup.title + '</h3></div>')

        // add members
        objGroup.member.forEach(function (objMember, idx) {
          appendItem(objMember, groupIdx)
        });

      });

      $teamSliderWrapper.append($slider);

      // add arrow buttons
      $buttonRight = $('<div class="button button-right"><svg  x="0px" y="0px" viewBox="0 0 199.4 199.4"><polygon points="64,199.4 163.7,99.7 64,0 35.7,28.3 107.1,99.7 35.7,171.1 "/></svg></div>');
      $buttonLeft = $('<div class="button button-left"><svg x="0px" y="0px"  viewBox="0 0 199.4 199.4" ><polygon points="135.4,0 35.7,99.7 135.4,199.4 163.7,171.1 92.3,99.7 163.7,28.3 \t"/></svg></div>');

      $buttonRight.on('click', function () {
        slide(-2)
      });
      $buttonLeft.on('click', function () {
        slide(2)
      });

      // hide left button by default
      TweenMax.set($buttonLeft, {xPercent: -100, autoAlpha: 0});

      $teamSliderWrapper.append($buttonRight);
      $teamSliderWrapper.append($buttonLeft);

      TweenMax.set($teamSliderWrapper, {xPercent: 100, autoAlpha: 0});

    }

    function adjustSliderWidth() {

      var $cards = $slider.find('.card');
      var $groupTitle = $slider.find('.group-title');
      var allItemsWidth = $cards.length * ($cards.first().width() + parseInt($cards.first().css('margin-left')) + 1);
      var allGroupTitleWidth = (function () {
        var w = 0;
        $groupTitle.each(function () {
          var $t = $(this);
          w += $t.width() + parseInt($t.css('margin-left'));
        })
        return w + 1; // one for safety
      })();

      $slider.width(allItemsWidth + allGroupTitleWidth)

      // // Set initial offset position of slider (doesn't work)
      // if (!isSliderOpen) {
      //   TweenMax.set($slider, {x: window.innerWidth * 0.2});
      // }
    }

    function checkMqSliderOnly(mq) {

      if (mq.matches) {
        $('#section4').addClass("slider-only")
        destroyParallax();
      }
      else {
        $('#section4').removeClass("slider-only")
        initParallax();
      }
    }

    buildSlider();

    // add listener for mobile switch
    mqSliderOnly.addListener(checkMqSliderOnly);
    checkMqSliderOnly(mqSliderOnly);

    // add resize listener and fire once
    window.addEventListener('resize', adjustSliderWidth);
    adjustSliderWidth();

    // switchDot
    $switchDot.on('click', function () {

      var tl = new TimelineMax({paused: true})

      TweenMax.to($switchDot, 0.15, {
        autoAlpha: 0,
        scale: 0.5,
        onComplete: function () {

          if (!isSliderOpen) {

            $switchDot.find('span').html($switchDot.attr('data-textopen'));
            $switchDot.appendTo('#teamSliderWrapper');

            // hide buttons
            TweenMax.set($buttonRight, {xPercent: 100, autoAlpha: 0});
            TweenMax.set($buttonLeft, {xPercent: -100, autoAlpha: 0});

            tl.to($teamScene, 0.4, {autoAlpha: 0, yPercent: 10}, 0);
            tl.to($teamSliderWrapper, 0.8, {xPercent: 0, autoAlpha: 1}, 0.2);
            tl.to($buttonRight, 0.5, {xPercent: 0, autoAlpha: 1});

          } else {

            $switchDot.find('span').html($switchDot.attr('data-textclose'));
            $switchDot.appendTo($switchDotOriginalParent);

            tl.to($buttonRight, 0.1, {xPercent: 100, autoAlpha: 0}, 0);
            tl.to($buttonLeft, 0.1, {xPercent: -100, autoAlpha: 0}, 0);
            tl.to($slider, 1, {x: 0}, 0.1);
            tl.to($teamSliderWrapper, 0.8, {xPercent: 100, autoAlpha: 1}, 0.1);
            tl.to($teamScene, 0.4, {autoAlpha: 1, yPercent: 0}, 0.6);
          }

          // show switch dot again
          tl.to($switchDot, 0.25, {autoAlpha: 1, scale: 1, delay: 0.2});

          // toggle flag
          isSliderOpen = !isSliderOpen;

          tl.play();
        }
      });
    });

    Draggable.create($slider, {
      bounds: $teamSliderWrapper,
      edgeResistance: 0.85,
      //dragResistance: 0.5,
      //force3D: false, // fixes performance rendering issues (I guess)
      //throwResistance:1000,
      throwProps: true,
      type: "x",
      cursor: "grab", // set initial cursor to grab
      onThrowComplete: function () {
        var currentX = $slider.get(0)._gsTransform ? $slider.get(0)._gsTransform.x : 0;
        toggleLeftButton(currentX);
      }
      // onPress: function () {
      //   $slider.addClass('dragging');
      // },
      // onRelease: function () {
      //   $slider.removeClass('dragging');
      // },
      /*snap:{
        x: function(endValue) {
          // snap to start or end
          var val = Math.round(endValue / 10) * 10;
          return val;
        }
      },*/
    });

  }

  initTeam();


  /**
   * Imprint
   */

  function initImprint() {

    var $overlay = $('#overlay');
    var $overlayToggler = $('[data-action="overlayToggler"]');
    var overlayIsOpen = $overlay.hasClass('active');
    var loaded = undefined;

    function openOverlay(target, jumpTo) {
      $overlay.addClass('active');
      // window.location.hash = `#${target}`;

      if (jumpTo && $(jumpTo)) {
        $('#overlay .content-wrapper').scrollTop($(jumpTo).offset().top - 20);
      }
    }

    function toggleOverlay(event, target = undefined, jumpTo = undefined) {

      if (!!event) {
        target  = $(event.currentTarget).data('target') || target;
        event.preventDefault();
      }

      if (overlayIsOpen) {
        $overlay.removeClass('active');
        // window.location.hash = '';
      }
      else if (loaded === target) {
        openOverlay(target, jumpTo)
      }
      else {

        $.get(`overlays/${target}.php`, function (data) {

          // append content and set flag
          $overlay.html(data);
          loaded = target;

          // bind close button handler
          $('#closeButton').on('click', toggleOverlay);

          // show overlay
          openOverlay(target, jumpTo)
        });
      }

      // toggle flag
      overlayIsOpen = !overlayIsOpen;
    }

    // bind handler
    $overlayToggler.on('click', toggleOverlay)

    // open imprint if hash is given in URL
    if (window.location.hash === '#impressum') {
      toggleOverlay(undefined, 'imprint');
    } else if (window.location.hash === '#jobs') {
      toggleOverlay(undefined, 'jobs');
    }

    // make cookie bar imprint link open in overlay
    $document.on('click', '.cc-link', function (e) {
      toggleOverlay(e, 'imprint', '#imprint-privacy');
    })

  }

  initImprint();


  /**
   * Scroll reveal
   */

  function isElementInViewport(element) {
    var rect = element.getBoundingClientRect();
    var offset = 50;

    return (
      rect.top + offset <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }

  var stripeIsInView = false;
  var isHeroCtaVisible = true;

  function initScrollReveal(windowScrollTop) {

    // var $animationWrapper = $('.animation-wrapper');
    //
    // function revealAnimationWrapper() {
    //
    //   var windowScrollTop = $window.scrollTop();
    //   // loop trough all items without animation
    //   $animationWrapper.each(function () {
    //
    //     var $t = $(this);
    //     var isAnimatedIn = $t.hasClass('in-view');
    //     var isVisible = ($t.offset().top - windowScrollTop + 80) <= window.innerHeight;
    //
    //     // console.log('offsetTOP:', $t.offset().top,'scrollTOP:',  windowScrollTop,'elHeight:',  $t.height(), 'innerH:', window.innerHeight )
    //     if (!isAnimatedIn && isVisible) {
    //
    //       $t.addClass('in-view');
    //     }
    //     else if (isAnimatedIn && !isVisible) {
    //
    //       // $t.removeClass('in-view will-animate');
    //       $t.removeClass('in-view');
    //     }
    //
    //   });
    // }

    var animatedElements = document.querySelectorAll(".animation-wrapper");

    function revealAnimationWrapper() {
      for (var i = 0; i < animatedElements.length; i++) {
        if (isElementInViewport(animatedElements[i])) {
          animatedElements[i].classList.add("in-view");
        }
        else {
          animatedElements[i].classList.remove("in-view");
        }
      }
    }

    function animateStripe(windowScrollTop) {

      // hide $scrollDownCta only for non mobile devices
      if (!isMobileDevice) {

        if (windowScrollTop > 100 && isHeroCtaVisible) {
          $scrollDownCta.addClass('hide').removeClass('shown');
          isHeroCtaVisible = false;
        }
        else if (windowScrollTop < 100 && !isHeroCtaVisible) {
          $scrollDownCta.removeClass('hide').addClass('shown');
          isHeroCtaVisible = true;
        }
      }

      if (windowScrollTop >= window.innerHeight / 3 && !stripeIsInView) {
        $('#stripe').addClass('in-view');
        stripeIsInView = true;
      } else if (windowScrollTop <= window.innerHeight / 3 && stripeIsInView) {
        $('#stripe').removeClass('in-view');
        stripeIsInView = false;
      }
    }

    // make one listener to only fetch scrollTop once
    function scrollRevealListener() {
      var windowScrollTop = $window.scrollTop();

      revealAnimationWrapper(windowScrollTop)
      animateStripe(windowScrollTop)
    }

    // add scroll listener and fire once
    window.addEventListener('scroll', scrollRevealListener);
    // window.addEventListener('scroll', $.throttle(5, false, scrollRevealListener));

    scrollRevealListener();
  }

  /**
   * Check if its a touch device
   */

    // detect user agent
  var ua = navigator.userAgent;

  var isTouchDevice = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua));

  if (isTouchDevice) {
    $('.widget-sidebar').addClass('hide-on-touch')
  }

  // Add helper class to body while resizing
  $window.on('resize', $.debounce(0, true, function () {
    $body.addClass('resizing');
  }, true));

  // Remove helper class from body after resizing
  $window.on('resize', $.debounce(100, false, function () {
    $body.removeClass('resizing');
  }));


  // Disable parallax on start scrolling to optimize performance, enable again if in view
  var heroParallaxEnabled = true;
  var teamParallaxEnabled = true;

  function disableParallax() {

    if (heroParallaxEnabled) {
      heroParallax.disable();
      heroParallaxEnabled = false;
    }

    if (teamParallaxInitialized && teamParallaxEnabled) {
      teamParallax.disable();
      teamParallaxEnabled = false;
    }
  }

  function enableParallax() {

    var windowScrollTop = $window.scrollTop();

    // hero
    if (!heroParallaxEnabled && windowScrollTop < window.innerHeight) {
      heroParallax.enable();
      heroParallaxEnabled = true;
    }

    // team
    if (teamParallaxInitialized && !teamParallaxEnabled && isElementInViewport(document.getElementById('teamScene'))) {
      teamParallax.enable();
      teamParallaxEnabled = true;
    }
  }

  $window.on('scroll', $.debounce(0, true, disableParallax, true));
  $window.on('scroll', $.debounce(50, false, enableParallax, true));

  initScrollReveal();

  /**
   * Jameda Overlay
   */

  var $jamedaOverlay = $('#jamedaOverlayWrapper').addClass('enabled');

  $('#jamedaButton, #jamedaButton2').click(function(e) {
    e.preventDefault();
    $jamedaOverlay.addClass('show');
  });
  $jamedaOverlay.find('.modal-close').click(function() {
    $jamedaOverlay.removeClass('show');
  });


  // wait some time
  setTimeout(function () {

    // check if images loaded
    imagesLoaded(('.hero-content'), {background: false}, animatePreloader);

  }, 500);

});
